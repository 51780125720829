/*
 * Copyright 2024 VMware, Inc.
 * All rights reserved.
 */

import { ChangeDetectorRef, Component, DestroyRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { dpaDebounce } from '@dpa/ui-common';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { IntelligenceCommonModule } from '@ws1c/intelligence-common';
import { CoreAppState, IntegrationMetaActions, IntegrationMetaSelectors } from '@ws1c/intelligence-core/store';
import { CustomAttributeIdentifier, CustomAttributeIdentifiersSearchRequest, SearchTerm } from '@ws1c/intelligence-models';

/**
 * CustomAttributeIdentifierSelectorComponent
 * @export
 * @class CustomAttributeIdentifierSelectorComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'dpa-custom-attribute-identifier-selector',
  templateUrl: 'custom-attribute-identifier-selector.component.html',
  styleUrls: ['custom-attribute-identifier-selector.component.scss'],
  standalone: true,
  imports: [IntelligenceCommonModule],
})
export class CustomAttributeIdentifierSelectorComponent implements OnInit {
  @Input() public selectedIdentifier: CustomAttributeIdentifier;
  @Input() public parentAttributeName: string;
  @Output() public identifierChange: EventEmitter<CustomAttributeIdentifier> = new EventEmitter<CustomAttributeIdentifier>();

  public isLoadingIdentifiers$: Observable<boolean>;
  public identifiers: CustomAttributeIdentifier[];

  /**
   * Creates an instance of CustomAttributeIdentifierSelectorComponent.
   * @param {Store<CoreAppState>} store
   * @param {ChangeDetectorRef} changeRef
   * @param {DestroyRef} destroyRef
   * @memberof CustomAttributeIdentifierSelectorComponent
   */
  constructor(
    private store: Store<CoreAppState>,
    private changeRef: ChangeDetectorRef,
    private destroyRef: DestroyRef,
  ) {
    this.isLoadingIdentifiers$ = this.store.select(IntegrationMetaSelectors.isLoadingCustomAttributeIdentifiers);
  }

  /**
   * ngOnInit
   * @memberof CustomAttributeIdentifierSelectorComponent
   */
  public ngOnInit() {
    this.store
      .select(IntegrationMetaSelectors.getCustomAttributeIdentifiers(this.parentAttributeName))
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((identifiers: CustomAttributeIdentifier[]) => {
        this.identifiers = identifiers;
        if (this.selectedIdentifier && !this.selectedIdentifier.label) {
          this.selectedIdentifier.label = this.identifiers?.find(
            (identifier: CustomAttributeIdentifier) => identifier.name === this.selectedIdentifier.name,
          )?.label;
        }
        this.changeRef.detectChanges();
      });

    this.loadIdentifiers();
  }

  /**
   * loadIdentifiers
   * @param {CustomAttributeIdentifiersSearchRequest} request
   * @memberof CustomAttributeIdentifierSelectorComponent
   */
  public loadIdentifiers(request?: CustomAttributeIdentifiersSearchRequest) {
    request = new CustomAttributeIdentifiersSearchRequest({
      ...request,
      customAttribute: this.parentAttributeName,
      size: 20,
    });
    this.store.dispatch(
      IntegrationMetaActions.loadCustomAttributeIdentifiers({
        customAttributeIdentifiersSearchRequest: request,
      }),
    );
  }

  /**
   * getIdentifiersKey
   * @param {string} columnsNestedDataIdentifiers
   * @returns {string}
   * @memberof CustomAttributeIdentifierSelectorComponent
   */
  public getIdentifiersKey(columnsNestedDataIdentifiers: CustomAttributeIdentifier): string {
    return columnsNestedDataIdentifiers.label;
  }

  /**
   * onIdentifierChange
   * @param {string} identifier
   * @memberof CustomAttributeIdentifierSelectorComponent
   */
  public onIdentifierChange(identifier: CustomAttributeIdentifier) {
    if (identifier) {
      const customAttributeIdentifiersByAttributeName = new Map<string, CustomAttributeIdentifier[]>([
        [this.parentAttributeName, [identifier]],
      ]);
      this.store.dispatch(
        IntegrationMetaActions.loadCustomAttributesIdentifierAttributes({
          customAttributeIdentifiersByAttributeName,
        }),
      );
    }
    this.identifierChange.emit(identifier);
  }

  /**
   * onIdentifierQueryChange
   * @param {string} query
   * @memberof CustomAttributeIdentifierSelectorComponent
   */
  @dpaDebounce(300)
  public onIdentifierQueryChange(query: string) {
    if (!query) {
      return;
    }
    this.loadIdentifiers(
      new CustomAttributeIdentifiersSearchRequest({
        searchTerms: [
          new SearchTerm({
            value: query,
          }),
        ],
      }),
    );
  }
}
